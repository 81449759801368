import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import PageIntroduction from "../components/PageIntroduction";
import Seo from "../components/seo";
import StoryItem from "../components/StoryItem";
import { Button, ButtonWrapper, staticImageFullHeightStyle } from "../styles";

const Donate = () => {
  return (
    <Layout>
      <Seo
        title="Donate"
        description="Donate your old IT equipment to our schools overseas. The Children’s Educational Trust relies on donations and is so very grateful for the amazing support we receive from companies, foundations, and private donors."
      />
      <Container className="mb-lg-5">
        <PageIntroduction
          title="Donate"
          body="The Children’s Educational Trust relies on donations and is so very grateful for the amazing support we receive from companies, foundations, and private donors. Through their support, we have been able to assist so many schools in improving their learning facilities."
        />
        <StoryItem
          title="DONATE YOUR OLD IT EQUIPMENT TO OUR SCHOOLS OVERSEAS"
          body={
            <>
              <p>
                Whilst your old technology equipment may be redundant and
                present you with a “nuisance” factor, it could make a
                significant difference to school children in Africa.
              </p>
              <p>
                So if you are updating your PCs, Laptops or Tablet equipment to
                new, please consider donating your old equipment to us. Please
                note we are only able to accept working equipment (cosmetic
                damage is acceptable) and are not able to accept broken or
                damaged equipment.
              </p>
              <ButtonWrapper>
                <Button>
                  <Link to={"/ContactUs"}>Donate your equipment</Link>
                </Button>
              </ButtonWrapper>
            </>
          }
          image={
            <StaticImage
              src={"../images/boy_studying_laptop.jpeg"}
              style={staticImageFullHeightStyle}
            />
          }
        />
      </Container>
    </Layout>
  );
};

export default Donate;
